@use '@/styles/utils/mixins.scss' as *;

.overviewSectionWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }

  .overviewImgBlock {
    position: relative;
    z-index: 1;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(5, 20, 31, 0) 40%,
        $kiaMidnightBlack 100%
      );
    }
    .overviewImage {
      @media screen and (max-width: 991.98px) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        height: auto;
      }
    }
  }

  .overviewDetails {
    .btnCta {
      @media screen and (max-width: 576px) {
        padding: 20px 15px;
      }
    }
  }
}
