@use '@/styles/utils/mixins.scss' as *;

.promotionsWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  background: var(--Primary-Colours-Kia-Midnight-Black, $kiaMidnightBlack);
  padding: 100px 56px;
  @media screen and (max-width: 576px) {
    padding: 40px 0px;
  }
  .promotionsCarousel {
    max-width: 1280px;
    width: 100%;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
    .swiper-wrapper {
      .swiper-slide {
        margin-right: 40px;
        @media screen and (min-width: 375px) and (max-width: 767px) {
          max-width: 400px;
        }
      }
    }
  }
  .promotionsSwiperControls {
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 1280px;
    width: 100%;
    margin: 40px auto 0 auto;
    @media screen and (min-width: 1024px) {
      display: none;
    }
    @media screen and (max-width: 576px) {
      margin: 28px 0 0 auto;
      padding-right: 24px;
    }

    &.showNavigation {
      padding-right: 0;
      @media screen and (min-width: 1024px) {
        display: flex;
      }
      @media screen and (max-width: 576px) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .swiper-navigation {
    display: flex;
    align-items: center;
  }
  .swiper-button-next-1,
  .swiper-button-prev-1 {
    width: 28px;
    height: 28px;
    margin-left: 6px;
    svg {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 576px) {
      width: 48px;
      height: 48px;
    }
  }
  .swiper-pagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 5px 10px;
    position: static;
    bottom: 0;
    right: 0;
    width: auto;
    left: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    span {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
  .swiper-pagination-lock {
    display: block;
  }
  .promotionsHeader {
    .headingElement {
      display: block;
    }
    .outlineWhite {
      border: 1px solid $keyline;
      margin: 12px 12px 12px 0;
      &:hover {
        border-color: $kiaPolarWhite;
      }
      &.active {
        border-color: $kiaPolarWhite;
      }
    }
  }
}
